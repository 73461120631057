import { useMemo, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import logo from '../../../assets/images/main/logo.svg';
import mail from '../../../assets/svg/mail.svg';
import mail_red from '../../../assets/svg/mail_red.svg';
import { APP_CONSTANTS, EMAIL_REGEX } from '@utils/constants';
import { cancelSubscriptionsRequest } from '@api/requests';

function UnsubscribeForm() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const isDisabled = useMemo(() => {
    return email === '';
  }, [email]);

  const handleContinue = () => {
    if (loading) {
      return;
    }

    const isValid = email.toLowerCase().match(EMAIL_REGEX);
    if (!isValid) {
      setError('Please enter a valid email');
      return;
    }

    setError(null);
    setLoading(true);

    cancelSubscriptionsRequest({
      email: email.toLowerCase(),
      emailing: true,
    })
      .then((response) => {
        if (response.success) {
          setModalOpen(true);
        }
      })
      .catch((error) => {
        console.error('cancelSubscriptions error', error);
        const errorMessage = typeof error === 'string' ? error : error.error ?? error.message;
        setError(typeof errorMessage === 'string' ? errorMessage : 'Something went wrong');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <QuizStepBackgroundWrapper>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          padding: '0 20px',
          maxWidth: '460px',
          margin: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '30px',
          }}
        >
          <img width={'72px'} height={'72px'} src={logo} alt="logo"></img>
          <Typography
            sx={{
              fontFamily: 'Avenir500',
              fontSize: '15px',
              userSelect: 'none',
              color: '#ED744E',
            }}
          >
            Terninate subscription
          </Typography>
        </Box>
        <Box
          sx={{
            padding: '20px 0 40px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontWeight: 600,
              fontSize: '24px',
              userSelect: 'none',
              textAlign: 'center',
              color: 'rgba(40, 38, 36, 1)',
              lineHeight: 'normal',
            }}
          >
            To cancel your subscription - please use the email used to purchase
          </Typography>
          <Box
            sx={{
              position: 'relative',
              '& img': {
                position: 'absolute',
                top: '22px',
                left: '20px',
              },
              '& input': {
                boxSizing: 'border-box',
                width: '100%',
                background: 'white',
                border: '1px solid rgba(240, 204, 185, 1)',
                borderRadius: '12px',
                color: 'rgba(40, 38, 36, 1)',
                fontFamily: 'Avenir500',
                fontSize: '16px',
                height: '60px',
                paddingLeft: '60px',
                '&:focus-visible': {
                  outline: 'none',
                },
              },
              '& input::placeholder': {
                color: 'rgba(144, 140, 137, 1)',
                fontFamily: 'Avenir400',
                fontSize: '16px',
                lineHeight: '22px',
              },
            }}
          >
            <img src={error ? mail_red : mail} alt="mail"></img>
            <input
              placeholder="Enter your email"
              type="email"
              value={email}
              onChange={(e) => {
                setError(null);
                setEmail(e.target.value);
              }}
            ></input>
            {error && (
              <Typography
                sx={{
                  color: '#E03045',
                  fontFamily: 'Avenir400',
                  fontSize: '14px',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                {error}
              </Typography>
            )}
          </Box>
          <Box marginBottom={'30px'}>
            <QuizButton disabled={isDisabled} onClick={handleContinue} variant={ButtonType.ORANGE} text="">
              {loading ? (
                <CircularProgress size={22} style={{ color: 'white' }}></CircularProgress>
              ) : (
                <>Cancel subscription</>
              )}
            </QuizButton>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              width: '100%',
              margin: 'auto 0 0',
              padding: '20px 0',
              borderTop: '1px solid rgba(240, 204, 185, 0.4)',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                fontSize: '14px',
                lineHeight: '19px',
                textAlign: 'center',
                color: '#282624',
                '& a': { color: '#ED744E', textDecoration: 'underline' },
              }}
            >
              Have a question? Reach our friendly support team <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>here</a>
            </Typography>
          </Box>
        </Box>
      </Box>
      {isModalOpen && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: 99,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(40, 38, 36, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              maxWidth: '335px',
              background: '#fff',
              borderRadius: '16px',
              padding: '30px 20px 20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img width={80} src="svg/email.svg" alt="email"></img>
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                fontWeight: 600,
                fontSize: '24px',
                userSelect: 'none',
                textAlign: 'center',
                color: '#282624',
                marginTop: '20px',
                lineHeight: 'normal',
              }}
            >
              Check your email
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                fontSize: '16px',
                userSelect: 'none',
                textAlign: 'center',
                color: '#908C89',
                lineHeight: 'normal',
                marginTop: '5px',
                marginBottom: '20px',
              }}
            >
              We have sent you an email confirming the end of the current subscription
            </Typography>
            <QuizButton
              onClick={() => {
                setModalOpen(false);
              }}
              variant={ButtonType.ORANGE}
              text="OK"
            ></QuizButton>
          </Box>
        </Box>
      )}
    </QuizStepBackgroundWrapper>
  );
}

export default UnsubscribeForm;
