import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Routes } from '../../../App';
import { trackEvent } from '@api/requests';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { getPriceWithDiscount } from '@utils/payment';
import { useNavigate } from 'react-router-dom';
import { getPathWithSearchParams } from '@utils/ulr';
import { preloadImage } from '@hooks/useImagePreloader';
import { setParamsToSessionStorage } from '@utils/storage';

function PackWrapper({
  image,
  isSelected,
  children,
  onClick,
}: {
  image: string;
  isSelected: boolean;
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        background: '#fff',
        borderRadius: '16px',
        padding: '10px 20px 10px 10px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        outline: isSelected
          ? '2px solid #ED744E'
          : '1px solid rgba(2, 2, 2, 0)',
      }}
    >
      <img width={60} src={image} alt="pack preview"></img>
      <Box
        sx={{
          width: '100%',
        }}
      >
        {children}
      </Box>
      {isSelected ? (
        <img src={'/refuse/svg/subs_checked_pack.svg'} alt="pack preview"></img>
      ) : (
        <Box
          sx={{
            minWidth: '24px',
            height: '24px',
            borderRadius: '50%',
            border: '1px solid rgba(2, 2, 2, 0.10)',
          }}
        ></Box>
      )}
    </Box>
  );
}
type OfferTypes =
  | 'ultimate_pack'
  | 'recovery_guide'
  | 'wellness_program'
  | 'skip_offer';

function AddPacks({
  setStepNumber,
  setWellnessProgramPurchased,
}: {
  setWellnessProgramPurchased: React.Dispatch<React.SetStateAction<boolean>>;
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
}) {
  const {
    isAdditionalPaymentLoading,
    additionalPaymentError,
    handleAdditionalOfferAdd,
  } = usePaymentState();

  const getAvailableOption = () => {
    const params = new URLSearchParams(window.location.search);
    switch (true) {
      case params.get('ult_pack') !== null: {
        return 'ultimate_pack';
      }
      case params.get('recovery_guide') !== null: {
        return 'recovery_guide';
      }
      case params.get('wellness_program') !== null: {
        return 'wellness_program';
      }
      default: {
        return 'skip_offer';
      }
    }
  };

  const [selectedOffer, setSelectedOffer] =
    useState<OfferTypes>(getAvailableOption());
  const {
    ultPackPlan,
    recoveryGuidePlan,
    wellnessProgramPlan,
    showAdditionalOffersPrice,
  } = usePaymentConfig();
  const { userLocation } = useQuizState();
  const navigate = useNavigate();

  useEffect(() => {
    if (getAvailableOption() === 'skip_offer') {
      navigate(getPathWithSearchParams(Routes.SIGN_IN));
    }

    const imageList = [
      '/refuse/images/subscription/image_coach.webp',
      '/refuse/images/subscription/image_custom_recovery_plan.webp',
      '/refuse/images/subscription/image_wellness_program.webp',
      '/refuse/svg/offer/p1.svg',
      '/refuse/svg/offer/p2.svg',
      '/refuse/svg/offer/p3.svg',
      '/refuse/svg/offer/p4.svg',
      '/refuse/svg/offer/p5.svg',
      '/refuse/svg/offer/p6.svg',
      '/refuse/svg/offer/p7.svg',
      '/refuse/svg/offer/p8.svg',
      '/refuse/svg/offer/p9.svg',
      '/refuse/svg/offer/p10.svg',
      '/refuse/svg/offer/p11.svg',
      '/refuse/svg/offer/p12.svg',
      '/refuse/images/subscription/image_wellness_program_white_.webp',
      '/refuse/images/subscription/image_custom_recovery_plan_white_.webp',
      '/refuse/images/sign-in.webp',
    ];
    const imagesPromiseList: Promise<any>[] = [];

    for (const i of imageList) {
      imagesPromiseList.push(preloadImage(i));
    }

    Promise.allSettled(imagesPromiseList);
  }, []);

  const handleGetTheOffer = useCallback(() => {
    if (isAdditionalPaymentLoading) return;

    switch (selectedOffer) {
      case 'ultimate_pack': {
        if (ultPackPlan) {
          handleAdditionalOfferAdd(ultPackPlan, () => {
            navigate(getPathWithSearchParams(Routes.SIGN_IN));
          });
        }
        return;
      }
      case 'recovery_guide': {
        if (recoveryGuidePlan) {
          handleAdditionalOfferAdd(recoveryGuidePlan, () => {
            setParamsToSessionStorage({
              isRecoveryGuidePurcahsed: 'true',
            });
            setStepNumber((prev) => prev + 2);
          });
        }
        return;
      }
      case 'wellness_program': {
        if (wellnessProgramPlan) {
          handleAdditionalOfferAdd(wellnessProgramPlan, () => {
            setParamsToSessionStorage({
              isWellnessProgramPurcahsed: 'true',
            });
            setWellnessProgramPurchased(true);
            setStepNumber((prev) => prev + 1);
          });
        }
        return;
      }
      case 'skip_offer': {
        setStepNumber((prev) => prev + 1);
        trackEvent(`Skip packs offers click`, userLocation).catch(
          console.error
        );
        return;
      }
    }
  }, [selectedOffer, ultPackPlan, recoveryGuidePlan, wellnessProgramPlan]);

  const planCost = useMemo(() => {
    switch (selectedOffer) {
      case 'ultimate_pack': {
        return ultPackPlan?.sum;
      }
      case 'recovery_guide': {
        return recoveryGuidePlan?.sum;
      }
      case 'wellness_program': {
        return wellnessProgramPlan?.sum;
      }
      case 'skip_offer': {
        return 0;
      }
    }
  }, [selectedOffer, ultPackPlan, recoveryGuidePlan, wellnessProgramPlan]);

  return (
    <Box
      sx={{
        maxWidth: '460px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        overflow: 'auto',
        margin: 'auto',
        padding: '0 20px',
      }}
    >
      <Typography
        sx={{
          margin: '20px 0 0',
          fontFamily: 'Avenir600',
          fontSize: '24px',
          lineHeight: '32px',
          color: '#020202',
          textAlign: 'center',
        }}
      >
        Exclusive sign-up offer
      </Typography>

      <Box
        sx={{
          margin: '20px 0',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '10px',
          '& .title': {
            fontFamily: 'Avenir600',
            fontSize: '16px',
            color: '#020202',
          },
          '& .subTitle': {
            fontFamily: 'Avenir400',
            fontSize: '10px',
            lineHeight: 'normal',
            color: '#020202',
            marginBottom: '5px',
          },
          '& .price_wrapper': {
            display: showAdditionalOffersPrice ? 'flex' : 'none',
            gap: '5px',
            alignItems: 'center',
          },
          '& .prev_price': {
            fontFamily: 'Avenir400',
            fontSize: '14px',
            color: '#A9A7AF',
            lineHeight: 'normal',
            textDecoration: 'line-through',
            textDecorationColor: '#E53E3E',
          },
          '& .curr_price': {
            fontFamily: 'Avenir600',
            fontSize: '14px',
            color: '#E53E3E',
            lineHeight: 'normal',
          },
          '& .discount': {
            fontFamily: 'Avenir600',
            fontSize: '10px',
            color: '#E53E3E',
            lineHeight: 'normal',
            display: 'flex',
            background: 'rgba(229, 62, 62, 0.10)',
            padding: '3px 8px',
            borderRadius: '20px',
          },
          '& .skipSubTitle': {
            fontFamily: 'Avenir400',
            fontSize: '14px',
            lineHeight: 'normal',
            color: '#A9A7AF',
          },
        }}
      >
        {ultPackPlan && (
          <PackWrapper
            image="/refuse/images/subscription/image_1.webp"
            isSelected={selectedOffer === 'ultimate_pack'}
            onClick={() => {
              setSelectedOffer('ultimate_pack');
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography className="title">Ultimate pack</Typography>
              <Typography className="subTitle">
                4 in 1: Personalized recovery guide, advanced wellness program,
                unlimited 24/7 AI coach & early access to all new features
              </Typography>
              <Box className="price_wrapper">
                <span className="prev_price">
                  $
                  {ultPackPlan &&
                    getPriceWithDiscount(
                      ultPackPlan.sum,
                      ultPackPlan.discount_percent
                    )}
                </span>
                <img src="/refuse/svg/pack_arrow.svg" alt="arrow"></img>
                <span className="curr_price">${ultPackPlan.sum}</span>
                <span className="discount">
                  {ultPackPlan.discount_percent}% OFF
                </span>
              </Box>
            </Box>
          </PackWrapper>
        )}

        {recoveryGuidePlan && (
          <PackWrapper
            image="/refuse/images/subscription/image_2.webp"
            isSelected={selectedOffer === 'recovery_guide'}
            onClick={() => {
              setSelectedOffer('recovery_guide');
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography className="title">Custom Recovery Guide</Typography>
              <Box className="price_wrapper">
                <span className="prev_price">
                  $
                  {recoveryGuidePlan &&
                    getPriceWithDiscount(
                      recoveryGuidePlan.sum,
                      recoveryGuidePlan.discount_percent
                    )}
                </span>
                <img src="/refuse/svg/pack_arrow.svg" alt="arrow"></img>
                <span className="curr_price">${recoveryGuidePlan.sum}</span>
                <span className="discount">
                  {recoveryGuidePlan.discount_percent}% OFF
                </span>
              </Box>
            </Box>
          </PackWrapper>
        )}

        {wellnessProgramPlan && (
          <PackWrapper
            image="/refuse/images/subscription/image_3.webp"
            isSelected={selectedOffer === 'wellness_program'}
            onClick={() => {
              setSelectedOffer('wellness_program');
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography className="title">Wellness Program</Typography>
              <Box className="price_wrapper">
                <span className="prev_price">
                  $
                  {wellnessProgramPlan &&
                    getPriceWithDiscount(
                      wellnessProgramPlan.sum,
                      wellnessProgramPlan.discount_percent
                    )}
                </span>
                <img src="/refuse/svg/pack_arrow.svg" alt="arrow"></img>
                <span className="curr_price">${wellnessProgramPlan.sum}</span>
                <span className="discount">
                  {wellnessProgramPlan.discount_percent}% OFF
                </span>
              </Box>
            </Box>
          </PackWrapper>
        )}

        <PackWrapper
          image="/refuse/images/subscription/image_4.webp"
          isSelected={selectedOffer === 'skip_offer'}
          onClick={() => {
            setSelectedOffer('skip_offer');
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography className="title">Skip offer</Typography>
            <Typography className="skipSubTitle">
              Missing all opportunities
            </Typography>
          </Box>
        </PackWrapper>
      </Box>
      {additionalPaymentError && (
        <Typography
          sx={{ margin: '10px 20px', color: '#E03045', textAlign: 'center' }}
        >
          {additionalPaymentError}
        </Typography>
      )}
      <QuizButton
        onClick={handleGetTheOffer}
        variant={ButtonType.ORANGE}
        text=""
      >
        {isAdditionalPaymentLoading ? (
          <CircularProgress
            size={20}
            style={{ color: 'white' }}
          ></CircularProgress>
        ) : (
          <>{selectedOffer === 'skip_offer' ? 'Letting go' : 'Get the offer'}</>
        )}
      </QuizButton>
      <Typography
        sx={{
          fontFamily: 'Avenir400',
          fontSize: '12px',
          lineHeight: 'normal',
          color: '#A9A7AF',
          textAlign: 'center',
          margin: '15px 0 30px',
          '& .link': {
            color: '#A9A7AF',
            textDecorationColor: '#A9A7AF',
          },
        }}
      >
        {selectedOffer !== 'skip_offer' && (
          <>
            By clicking the button, I agree to enroll in a one-time subscription
            at the cost of ${planCost}. I have thoroughly reviewed and accepted
            the{' '}
            <Link target="_blank" href={Routes.TERMS} className="link">
              Terms and Conditions.
            </Link>
          </>
        )}
      </Typography>
    </Box>
  );
}

export default AddPacks;
