import { Box, Typography } from '@mui/material';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import logo from '../../../assets/images/main/logo.svg';
import { APP_CONSTANTS } from '@utils/constants';
import { useEffect, useState } from 'react';
import { cancelSubscriptionsWithParameter } from '@api/requests';

function CancelSubscription() {
  const [texts, setTexts] = useState({ title: '', subTitle: '' });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const content = urlParams.get('from');
    if (content) {
      cancelSubscriptionsWithParameter({
        content: content,
      })
        .then((response) => {
          setTexts({
            title: 'Your subscription has been canceled',
            subTitle: "We've successfully cancelled your subscription.",
          });
        })
        .catch((error) => {
          console.error('cancelSubscriptionsWithParameter error', error);
          setTexts({
            title: 'No subscriptions',
            subTitle:
              'Unfortunately there are no subscription plans attached to this mail. Check if the specified email address is correct',
          });
        });
    }
  }, []);

  return (
    <QuizStepBackgroundWrapper>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          padding: '0 20px',
          maxWidth: '460px',
          margin: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '30px',
          }}
        >
          <img width={'72px'} height={'72px'} src={logo} alt="logo"></img>
        </Box>
        <Box
          sx={{
            padding: '20px 0 40px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontWeight: 600,
              fontSize: '24px',
              userSelect: 'none',
              textAlign: 'center',
              color: 'rgba(40, 38, 36, 1)',
              lineHeight: 'normal',
            }}
          >
            {texts.title}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Avenir400',
              fontSize: '16px',
              userSelect: 'none',
              textAlign: 'center',
              color: '#908C89',
              lineHeight: 'normal',
              marginTop: '10px',
              marginBottom: '20px',
            }}
          >
            {texts.subTitle}
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              width: '100%',
              margin: 'auto 0 0',
              padding: '20px 0',
              borderTop: '1px solid rgba(240, 204, 185, 0.4)',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                fontSize: '14px',
                lineHeight: '19px',
                textAlign: 'center',
                color: '#282624',
                '& a': { color: '#ED744E', textDecoration: 'underline' },
              }}
            >
              Have a question? Reach our friendly support team <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>here</a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </QuizStepBackgroundWrapper>
  );
}

export default CancelSubscription;
