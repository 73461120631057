import { Route, Routes as RoutesInner } from 'react-router-dom';
import Main from './components/Main';
import Quiz from './components/Quiz';
import Subscription from './components/Subscription';
import SignIn from './components/SignIn';
import DownloadApp from './components/DownloadApp';
import Privacy from './components/Docs/Privacy';
import Terms from './components/Docs/Terms';
import CookiePolicy from './components/Docs/CookiePolicy';
import React from 'react';
import { Routes } from './App';
import { useCheckAuth } from '@hooks/useCheckAuth';
import Advantages from '@components/Advantages';
import Unsubscribe from '@components/Unsubscribe/Unsubscribe';
import Offers from '@components/Offers/Offers';

export default function Router() {
  useCheckAuth();

  return (
    <RoutesInner>
      <Route path={Routes.MAIN + '*'} element={<Main />} />
      <Route path={Routes.QUIZ} element={<Quiz />} />
      <Route path={Routes.SUBSCRIPTION} element={<Subscription />} />
      <Route path={Routes.ADVANTAGES} element={<Advantages />} />
      <Route path={Routes.SIGN_IN} element={<SignIn />} />
      <Route path={Routes.OFFERS} element={<Offers />} />
      <Route path={Routes.ACTIVATE_ME} element={<SignIn />} />
      <Route path={Routes.DOWNLOAD_APP} element={<DownloadApp />} />
      <Route path={Routes.PRIVACY} element={<Privacy />} />
      <Route path={Routes.TERMS} element={<Terms />} />
      <Route path={Routes.COOKIE_POLICY} element={<CookiePolicy />} />
      <Route path={Routes.UNSUBSCRIBE} element={<Unsubscribe />} />
    </RoutesInner>
  );
}
