export enum EvTruck {
    IntroShown = 'intro_shown',
    IntroSuccess = 'intro_success',
    EmailSuccess = 'email_success',
    EmailShown = 'email_shown',
    ProcessingShown = 'processing_shown',
    AdvantagesShown = 'advantages_shown',
    SubscriptionShown = 'subscription_shown',
    ModalPaymentShown = 'modal_payment_shown',
    CardSubmit = 'card_submit',
    ApplePaySubmit = 'apple_pay_submit',
    ModalPaymentSubmit = 'modal_payment_submit',
    Purchase = 'ecommerce_purchase',
    PurchaseError = 'ecommerce_purchase_error',
    AdditionalPurchase = 'ecommerce_additional_purchase',
    AdditionalPurchaseError = 'ecommerce_additional_purchase_error',
    OffersShown = 'offers_shown',
    SignInScreenShown = 'sign_in_shown',
    DownloadAppScreenShown = 'download_app_shown',
}
