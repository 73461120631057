import React, { useEffect, useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import Logo from '../../assets/svg/logo.svg';
import LogoOutlined from '../../assets/svg/logo-outlined.svg';

import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { trackEvent } from '@api/requests';
import QuizButton, { ButtonType } from '../common/QuizButton';
import { APP_CONSTANTS } from '@utils/constants';
import { useUser } from '@providers/UserProvider';
import { getAttribution } from '@utils/attribution';
import { mergeSearchParamsIntoUrl } from '@utils/ulr';
import { filterFalsyValues } from '@utils/common';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { getPixelValue } from '@utils/pixel';
import { isIos } from '@utils/browser';
import { getParamsFromSessionStorage } from '@utils/storage';
import { wmq } from '@services/magnus';
import { EvTruck } from '@models/events';

function DownloadApp() {
  const { userLocation } = useQuizState();

  const { paymentConfig } = usePaymentState();

  const { user } = useUser();

  useEffect(() => {
    trackEvent(`Download screen shown`, userLocation);
    wmq.track(EvTruck.DownloadAppScreenShown);
  }, []);

  function handleClick() {
    const token = user?.isAppleAuth ? user?.providerDataId ?? user?.uid : user?.uid;

    const attribution = getAttribution({
      ip: userLocation?.ip ?? '',
      country: userLocation?.country ?? '',
    });

    const link = mergeSearchParamsIntoUrl(
      isIos()
        ? paymentConfig?.download?.ios ?? APP_CONSTANTS.IOS_DOWNLOAD_APP_LINK
        : paymentConfig?.download?.android ?? APP_CONSTANTS.ANDROID_DOWNLOAD_APP_LINK,
      filterFalsyValues({
        af_ad_id: attribution?.ad_id,
        af_adset_id: attribution?.adset_id,
        af_c_id: attribution?.campaign_id,
        af_ad: attribution?.ad_name,
        af_adset: attribution?.adset_name,
        c: attribution?.campaign_name,
        campaign: attribution?.campaign_name,
        af_sub1: paymentConfig?.pixel ? getPixelValue(paymentConfig?.pixel) : '',
        af_sub2: attribution?.fbc,
        af_sub3: attribution?.fbp,
        pid: 'quiz',
        af_sub4: token,
        af_sub5: user?.providerId?.slice(0, user?.providerId?.indexOf('.')),
      })
    );

    window.open(link, '_blank');
  }

  const purchases = useMemo(() => {
    const sessionPaymentParams = getParamsFromSessionStorage([
      'isRecoveryGuidePurcahsed',
      'isWellnessProgramPurcahsed',
    ]);
    const result = [];
    if (sessionPaymentParams.isRecoveryGuidePurcahsed === 'true') {
      result.push({
        image: '/refuse/images/subscription/image_custom_recovery_plan_white_.webp',
        title: 'Personalised Guide Purchased',
        subTitle: (
          <>
            You’ve successfully purchased the guide. <span>It is ready in the Refuse app</span>, install it now and
            start your journey.
          </>
        ),
      });
    }
    if (sessionPaymentParams.isWellnessProgramPurcahsed === 'true') {
      result.push({
        image: '/refuse/images/subscription/image_wellness_program_white_.webp',
        title: 'Wellness Plan Purchased',
        subTitle: (
          <>
            Your plan is <span>waiting in the Refuse app</span>. Download the app now and start rebuild your physical
            health
          </>
        ),
      });
    }
    return result;
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#FCF4EF',
        width: '100%',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '460px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            '& .logo': {
              margin: '30px auto 0',
            },
          }}
        >
          <img className="logo" src={Logo} width={72} height={72} alt="logo"></img>

          <Box
            sx={{
              flex: 1,
              margin: '20px 0 0',
              padding: '0px 20px 20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir500',
                fontSize: '15px',
                lineHeight: '20px',
                color: '#ED744E',
                textAlign: 'center',
                userSelect: 'none',
              }}
            >
              Congratulations
            </Typography>
            <Typography
              sx={{
                margin: '10px 0 0',
                fontFamily: 'Avenir600',
                fontSize: '24px',
                color: '#282624',
                textAlign: 'center',
                userSelect: 'none',
              }}
            >
              We’ve automatically upgraded your account!{' '}
            </Typography>

            <Typography
              sx={{
                fontFamily: 'Avenir400',
                fontSize: '16px',
                lineHeight: '22px',
                color: '#908C89',
                textAlign: 'center',
                userSelect: 'none',
                margin: '10px 0',
              }}
            >
              {purchases.length > 0
                ? "Below are the products you purchased and a guide on how to use the app. Let's redefine your relationship with alcohol!"
                : 'You already have a Refuse account, so if you currently have the app installed, just open the app! If not, follow the directions below.'}
            </Typography>

            {purchases.length > 0 && (
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
                  {purchases.map((el) => (
                    <Box
                      key={el.title}
                      sx={{
                        width: '100%',
                        padding: '20px',
                        background: '#fff',
                        borderRadius: '20px',
                        boxShadow: '0px 0px 8px 0px rgba(223, 210, 201, 0.20)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <img width={200} src={el.image} alt={el.title}></img>
                      <Typography
                        sx={{
                          margin: '20px 0 10px',
                          fontFamily: 'Avenir600',
                          fontSize: '20px',
                          color: '#282624',
                          textAlign: 'center',
                          userSelect: 'none',
                        }}
                      >
                        {el.title}
                      </Typography>

                      <Typography
                        sx={{
                          fontFamily: 'Avenir400',
                          fontSize: '16px',
                          lineHeight: 'normal',
                          color: '#282624',
                          textAlign: 'center',
                          userSelect: 'none',
                          marginBottom: '20px',
                          span: {
                            fontFamily: 'Avenir600',
                            color: '#ED744E',
                          },
                        }}
                      >
                        {el.subTitle}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                <Box
                  sx={{
                    width: '100%',
                    height: '1px',
                    background: 'rgba(240, 204, 185, 0.4)',
                    margin: '40px 0',
                  }}
                ></Box>
                <Typography
                  sx={{
                    margin: '10px 0 0',
                    fontFamily: 'Avenir600',
                    fontSize: '24px',
                    color: '#282624',
                    textAlign: 'center',
                    userSelect: 'none',
                  }}
                >
                  How to use the Refuse app
                </Typography>

                <Typography
                  sx={{
                    fontFamily: 'Avenir400',
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#908C89',
                    textAlign: 'center',
                    userSelect: 'none',
                    margin: '10px 0',
                  }}
                >
                  You already have a Refuse account, so if you currently have the app installed, just open the app! If
                  not, follow the directions below.
                </Typography>
              </Box>
            )}
            <img src="/refuse/svg/congrats_arrow.svg" alt="arrow"></img>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  background: 'white',
                  width: '100%',
                  margin: '20px 0 0',
                  padding: '20px',
                  borderRadius: '12px',
                  boxShadow: '0px 0px 8px 0px #DFD2C933',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      fontSize: '29px',
                      lineHeight: 'normal',
                      color: '#282624',
                      textAlign: 'center',
                    }}
                  >
                    Step 1
                  </Typography>
                  <Typography
                    sx={{
                      // margin: '5px 0 0',
                      fontFamily: 'Avenir400',
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: '#282624',
                      textAlign: 'center',
                    }}
                  >
                    Download application from {isIos() ? 'AppStore' : 'Google Play'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: '1px',
                    background: 'rgba(240, 204, 185, 0.4)',
                  }}
                ></Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px',
                  }}
                >
                  <img src={LogoOutlined} alt="logo" />

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Avenir600',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#282624',
                      }}
                    >
                      Refuse
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Avenir400',
                        fontSize: '12px',
                        lineHeight: '17px',
                        color: '#908C89',
                      }}
                    >
                      Neuroscience-based alcohol reducation app
                    </Typography>
                  </Box>
                </Box>
                <QuizButton text="Download app" variant={ButtonType.ORANGE} onClick={handleClick} />
              </Box>
              <img width={59} src="/refuse/svg/congrats_arrow.svg" alt="arrow"></img>

              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    margin: '20px 0 0',
                    padding: '20px',
                    background: 'white',
                    borderRadius: '12px',
                    boxShadow: '0px 0px 8px 0px #DFD2C933',
                    '& .sign-in-image': {
                      marginTop: '20px',
                      borderTop: '1px solid rgba(240, 204, 185, 0.4)',
                    },
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Avenir600',
                        fontSize: '24px',
                        lineHeight: 'normal',
                        color: '#282624',
                        textAlign: 'center',
                      }}
                    >
                      Step 2
                    </Typography>

                    <Typography
                      sx={{
                        fontFamily: 'Avenir400',
                        fontSize: '16px',
                        lineHeight: 'normal',
                        color: '#282624',
                        textAlign: 'center',
                      }}
                    >
                      Open it and sign in to your account
                    </Typography>
                  </Box>
                  <img width={'100%'} className="sign-in-image" src="/refuse/images/sign-in.webp" alt="sign-in" />
                </Box>
              </Box>
              <img width={59} src="/refuse/svg/congrats_arrow.svg" alt="arrow"></img>
            </Box>

            <Typography
              sx={{
                marginBottom: '40px',
                marginTop: '10px',
                fontFamily: 'Avenir600',
                fontSize: '24px',
                lineHeight: '32px',
                color: '#282624',
                textAlign: 'center',
                userSelect: 'none',
                span: {
                  color: '#ED744E',
                },
              }}
            >
              If you currently have the app installed, <span>just open the app!</span>
            </Typography>

            <Box
              sx={{
                width: '100%',
                margin: 'auto 0 0',
                padding: '20px 0',
                borderTop: '1px solid rgba(240, 204, 185, 0.4)',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  fontSize: '14px',
                  lineHeight: '19px',
                  textAlign: 'center',
                  color: '#282624',
                  '& a': { color: '#ED744E', textDecoration: 'underline' },
                }}
              >
                Have a question? Reach our friendly support team <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>here</a>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DownloadApp;
