import CancelSubscription from './components/CancelSubscription';
import UnsubscribeForm from './components/UnsubscribeForm';

function Unsubscribe() {
  const urlParams = new URLSearchParams(window.location.search);
  const isCancel = urlParams.get('from') !== null && urlParams.get('from') !== '';
  return isCancel ? <CancelSubscription /> : <UnsubscribeForm />;
}

export default Unsubscribe;
