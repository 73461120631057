import { EvTruck } from "@models/events"

declare global {
    interface Window {
        wmq?: any
    }
}

class WMQManager {
    private magnusId: string
    isInitialized: boolean
    eventTrackingEnabled: boolean

    constructor({
        magnusId,
        eventTrackingEnabled,
    }: {
        magnusId: string
        eventTrackingEnabled: boolean
    }) {
        this.magnusId = magnusId
        this.eventTrackingEnabled = eventTrackingEnabled
        this.isInitialized = false
    }

    public init(magnusId?: string) {
        if (this.isInitialized || !this.eventTrackingEnabled) return

        this.magnusId = magnusId ?? this.magnusId

        if (window.wmq) {
            window.wmq('init', this.magnusId)
            this.isInitialized = true
        }
    }

    public track(
        targetEvent: EvTruck | string | EvTruck[],
        params?: Record<string, any>
    ) {
        if (!this.eventTrackingEnabled) return

        const trackEvent = (
            targetEvent: EvTruck | string,
            params?: Record<string, any>
        ) => {
            if (window.wmq) {
                window.wmq('track', targetEvent, {
                    ...params,
                })
            }
        }

        if (Array.isArray(targetEvent)) {
            targetEvent.forEach((event) => {
                trackEvent(event, params)
            })
        } else {
            trackEvent(targetEvent, params)
        }
    }
}

export const wmq = new WMQManager({
    magnusId: process.env.REACT_APP_MAGNUS_TOKEN ?? '',
    eventTrackingEnabled:
        new URLSearchParams(window.location.search).get('wmq') === '1',
})
