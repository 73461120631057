import React, { useMemo } from 'react';
import { Box, Checkbox, Link, Typography } from '@mui/material';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { Routes } from '../../../App';
import shield from '../../../assets/svg/shield.svg';
import payment_methods from '../../../assets/svg/payment_methods.svg';
import checkbox_checked from '../../../assets/svg/checked.svg';
import check_icon from '../../../assets/svg/check_icon.svg';
import star from '../../../assets/svg/star.svg';
import { endTrial } from '@utils/common';
import { APP_CONSTANTS } from '@utils/constants';
import { plansUtils } from '@services/planUtils';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { wmq } from '@services/magnus';
import { EvTruck } from '@models/events';

function ChoosePlan() {
  const { setQuizData, quizData } = useQuizState();

  const { paymentConfig, setSelectedPlan, selectedPlan } = usePaymentConfig();

  const paymentPlans = useMemo(
    () => paymentConfig?.plans ?? [],
    [paymentConfig?.plans]
  );

  const handleOpenModal = () => {
    setQuizData((prev) => ({ ...prev, isPaymentModalOpen: true }));
    wmq.track(EvTruck.ModalPaymentShown);
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          marginTop: '30px',
        }}
      >
        {paymentPlans?.map((plan, index) => {
          const length = plan.trial_length ? plan.trial_length : plan.length;

          const planName = plansUtils.getPlaneName(
            length,
            Boolean(plan.trial_length)
          );

          const trialValue =
            plan.trial_length !== null ? plan.trial_sum : plan.sum;

          const discountValue = plansUtils.getPriceWithDiscount(
            trialValue,
            plan.discount_percent ?? 30
          );

          return (
            <Box
              key={plan.name}
              sx={{
                borderRadius: '16px',
                padding: index === 0 ? '0px 8px 8px 20px' : '8px 8px 8px 20px',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                background: 'white',
                border:
                  index === 0 ? '2px solid #ED744E' : '1px solid transparent',
                overflow: 'hidden',
                boxShadow: '0px 0px 8px 0px #DFD2C933',
              }}
              onClick={() => setSelectedPlan(plan)}
            >
              {index === 0 && (
                <Box
                  sx={{
                    background: '#ED744E',
                    marginBottom: '8px',
                    marginLeft: '-20px',
                    marginRight: '-8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '3px',
                  }}
                >
                  <img src={star} alt="star"></img>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir700',
                      fontSize: '10px',
                      color: '#fff',
                      userSelect: 'none',
                      textTransform: 'uppercase',
                      margin: '4px 0',
                    }}
                  >
                    most popular PLAN
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    marginRight: '10px',
                  }}
                >
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      outline:
                        plan.id === selectedPlan?.id
                          ? undefined
                          : '1px solid rgba(143, 154, 203, 0.40)',
                      borderRadius: '50%',
                    }}
                  >
                    {plan.id === selectedPlan?.id && (
                      <img src={check_icon} alt="checked"></img>
                    )}
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      fontSize: '16px',
                      userSelect: 'none',
                      color: '#282624',
                    }}
                  >
                    {planName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor: '#FCF4EF',
                    borderRadius: '8px',
                    width: '120px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '8px 0',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      fontSize: '14px',
                      color: '#ED744E',
                      userSelect: 'none',
                      textDecoration: 'line-through',
                    }}
                  >
                    $3.49
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      fontSize: '20px',
                      userSelect: 'none',
                      color: '#282624',
                    }}
                  >
                    ${trialValue}
                  </Typography>
                  {/*<Typography*/}
                  {/*  sx={{*/}
                  {/*    fontFamily: 'Avenir400',*/}
                  {/*    fontSize: '10px',*/}
                  {/*    userSelect: 'none',*/}
                  {/*    color: '#908C89',*/}
                  {/*    textTransform: 'uppercase',*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  per day*/}
                  {/*</Typography>*/}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ display: 'flex', gap: '10px', margin: '20px 0' }}>
        <Box
          sx={{
            marginTop: '-5px',
            marginLeft: '5px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Checkbox
            checked={quizData.isAgreed}
            value={quizData.isAgreed}
            onClick={() =>
              setQuizData((prev) => ({ ...prev, isAgreed: !prev.isAgreed }))
            }
            checkedIcon={<img src={checkbox_checked} alt="checkbox"></img>}
            sx={{
              color: '#ED744E',
            }}
          ></Checkbox>
        </Box>
        <Typography
          sx={{
            color: '#7A82A6',
            fontFamily: 'Avenir400',
            fontSize: '12px',
            userSelect: 'none',
            '& .link': {
              color: '#ED744E',
              textDecoration: 'underline',
              transition: 'all 0.3s ease',
              whiteSpace: 'nowrap',
              '&:hover': {
                opacity: 0.8,
              },
            },
          }}
        >
          I got acquainted with the{' '}
          <Link href={Routes.TERMS} className="link" target="_blank">
            terms of use
          </Link>{' '}
          and the rules of auto-renewal subscription
        </Typography>
      </Box>
      <QuizButton
        disabled={!quizData.isAgreed}
        onClick={handleOpenModal}
        variant={ButtonType.ORANGE}
        text="Continue"
      ></QuizButton>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          margin: '20px 0',
        }}
      >
        <img src={shield} alt="shield"></img>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '14px',
            lineHeight: 'normal',
            textAlign: 'center',
            userSelect: 'none',
            color: '#7A82A6',
          }}
        >
          100% money back guarantee
        </Typography>
      </Box>
      <Box
        sx={{
          background: '#F9E9E0',
          borderRadius: '16px',
          padding: '15px 20px 20px',
          '& img': {
            width: '100%',
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir500',
            fontSize: '14px',
            lineHeight: 'normal',
            textAlign: 'center',
            color: '#282624',
            userSelect: 'none',
            marginBottom: '10px',
          }}
        >
          Safe & Secure Payment
        </Typography>
        <img draggable={false} src={payment_methods} alt="payments"></img>
      </Box>
      <Typography
        sx={{
          fontFamily: 'Avenir400',
          fontSize: '12px',
          lineHeight: 'normal',
          textAlign: 'center',
          userSelect: 'none',
          color: '#908C89',
          marginTop: '20px',
          '& a': {
            color: '#908C89',
          },
        }}
      >
        Your {selectedPlan?.name} intro offer last until{' '}
        {endTrial(
          selectedPlan?.trial_length || '',
          Number(
            selectedPlan?.trial_length.slice(
              0,
              selectedPlan?.trial_length.length - 1
            )
          ) || 0
        )}
        . Then your weekly subscription will start and you'll be charged ${' '}
        {selectedPlan?.sum} every 1 week. You can cancel anytime in your profile
        or just write us a letter:{' '}
        <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>{APP_CONSTANTS.EMAIL}</a>
      </Typography>
    </Box>
  );
}

export default ChoosePlan;
