import { useEffect, useMemo, useState } from 'react';
import OffersHeader from './components/OffersHeader';
import { Box } from '@mui/material';
import AddPacks from './steps/AddPacks';
import RecoveryGuide from './steps/RecoveryGuide';
import WellnessProgram from './steps/WellnessProgram';
import AICoach from './steps/AICoach';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { useNavigate } from 'react-router-dom';
import { getPathWithSearchParams } from '@utils/ulr';
import { Routes } from '../../App';
import { wmq } from '@services/magnus';
import { EvTruck } from '@models/events';

function Offers() {
  const [stepNumber, setStepNumber] = useState(0);
  const { recoveryGuideSalePlan, wellnessProgramSalePlan, aiCoachPlan } = usePaymentConfig();
  const [isWellnessProgramPurchased, setWellnessProgramPurchased] = useState(false);
  const [stepToShow, setStepToShow] = useState<JSX.Element | null>(null);
  const navigate = useNavigate();

  const stepsToShow = useMemo(() => {
    const arr = [
      {
        name: 'Add\nPacks',
        component: <AddPacks setStepNumber={setStepNumber} setWellnessProgramPurchased={setWellnessProgramPurchased} />,
      },
    ];
    recoveryGuideSalePlan &&
      arr.push({
        name: 'Recovery\nGuide',
        component: (
          <RecoveryGuide setStepNumber={setStepNumber} isWellnessProgramPurchased={isWellnessProgramPurchased} />
        ),
      });
    wellnessProgramSalePlan &&
      arr.push({
        name: 'Wellness\nProgram',
        component: <WellnessProgram setStepNumber={setStepNumber} />,
      });
    aiCoachPlan &&
      arr.push({
        name: '24/7\nCoach',
        component: <AICoach setStepNumber={setStepNumber} />,
      });
    return arr;
  }, [recoveryGuideSalePlan, wellnessProgramSalePlan, aiCoachPlan, isWellnessProgramPurchased]);

  useEffect(() => {
    if (stepNumber === stepsToShow.length) {
      navigate(getPathWithSearchParams(Routes.SIGN_IN));
    } else {
      setStepToShow(stepsToShow[stepNumber].component);
    }
  }, [stepNumber, stepsToShow, navigate]);

  useEffect(() => {
    wmq.track(EvTruck.OffersShown);
  }, []);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: '#FCF4EF',
        }}
      >
        <OffersHeader steps={stepsToShow} stepNumber={stepNumber} />
        {stepToShow}
      </Box>
    </>
  );
}

export default Offers;
