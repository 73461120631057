import { createContext, ReactNode, useEffect, useState } from 'react';
import { PaymentConfigType, PaymentPlanType } from '@providers/PaymentProvider/PaymentProvider.types';
import { getAdditionalPlan, getPaymentConfig } from '@api/requests';

export const PaymentConfigContext = createContext<null | {
  paymentConfig: null | PaymentConfigType;
  selectedPlan: null | PaymentPlanType;
  ultPackPlan: null | PaymentPlanType;
  recoveryGuidePlan: null | PaymentPlanType;
  recoveryGuideSalePlan: null | PaymentPlanType;
  wellnessProgramPlan: null | PaymentPlanType;
  wellnessProgramSalePlan: null | PaymentPlanType;
  aiCoachPlan: null | PaymentPlanType;
  showAdditionalOffersPrice: boolean;
  setSelectedPlan: React.Dispatch<React.SetStateAction<PaymentPlanType | null>>;
}>(null);

function PaymentConfigProvider({ children }: { children: ReactNode }) {
  const [paymentConfig, setPaymentConfig] = useState<null | PaymentConfigType>(null);

  const [selectedPlan, setSelectedPlan] = useState<PaymentPlanType | null>(null);

  const [ultPackPlan, setUltPackPlan] = useState<PaymentPlanType | null>(null);
  const [recoveryGuidePlan, setRecoveryGuidePlan] = useState<PaymentPlanType | null>(null);
  const [recoveryGuideSalePlan, setRecoveryGuideSalePlan] = useState<PaymentPlanType | null>(null);
  const [wellnessProgramPlan, setWellnessProgramPlan] = useState<PaymentPlanType | null>(null);
  const [wellnessProgramSalePlan, setWellnessProgramSalePlan] = useState<PaymentPlanType | null>(null);
  const [aiCoachPlan, setAiCoachPlan] = useState<PaymentPlanType | null>(null);
  const showAdditionalOffersPrice = new URLSearchParams(window.location.search).get('additional_offer_dc') === 'true';

  useEffect(() => {
    getPaymentConfig()
      .then((response) => {
        setPaymentConfig(response);
        setSelectedPlan(response.plans[0]);
      })
      .catch(console.error);

    const ult_pack = new URLSearchParams(window.location.search).get('ult_pack');
    if (ult_pack) {
      getAdditionalPlan(ult_pack)
        .then((response) => {
          if (response.plans) {
            setUltPackPlan(response.plans[0]);
          }
        })
        .catch(console.error);
    }
    const recovery_guide = new URLSearchParams(window.location.search).get('recovery_guide');
    if (recovery_guide) {
      getAdditionalPlan(recovery_guide)
        .then((response) => {
          if (response.plans) {
            setRecoveryGuidePlan(response.plans[0]);
          }
        })
        .catch(console.error);
    }
    const recovery_guide_s = new URLSearchParams(window.location.search).get('recovery_guide_s');
    if (recovery_guide_s) {
      getAdditionalPlan(recovery_guide_s)
        .then((response) => {
          if (response.plans) {
            setRecoveryGuideSalePlan(response.plans[0]);
          }
        })
        .catch(console.error);
    }
    const wellness_program = new URLSearchParams(window.location.search).get('wellness_program');
    if (wellness_program) {
      getAdditionalPlan(wellness_program)
        .then((response) => {
          if (response.plans) {
            setWellnessProgramPlan(response.plans[0]);
          }
        })
        .catch(console.error);
    }
    const wellness_program_s = new URLSearchParams(window.location.search).get('wellness_program_s');
    if (wellness_program_s) {
      getAdditionalPlan(wellness_program_s)
        .then((response) => {
          if (response.plans) {
            setWellnessProgramSalePlan(response.plans[0]);
          }
        })
        .catch(console.error);
    }
    const ai_coach = new URLSearchParams(window.location.search).get('ai_coach');
    if (ai_coach) {
      getAdditionalPlan(ai_coach)
        .then((response) => {
          if (response.plans) {
            setAiCoachPlan(response.plans[0]);
          }
        })
        .catch(console.error);
    }
  }, []);

  const value = {
    paymentConfig,
    selectedPlan,
    setSelectedPlan,
    ultPackPlan,
    recoveryGuidePlan,
    recoveryGuideSalePlan,
    wellnessProgramPlan,
    wellnessProgramSalePlan,
    aiCoachPlan,
    showAdditionalOffersPrice
  };

  return <PaymentConfigContext.Provider value={value}>{children}</PaymentConfigContext.Provider>;
}

export default PaymentConfigProvider;
