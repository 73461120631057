import React from 'react';
import { QuizProvider } from '@providers/QuizProvider/QuizProvider';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { PaymentProvider } from '@providers/PaymentProvider/PaymentProvider';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import useImagePreloader from '@hooks/useImagePreloader';
import imagePaths from '@constants/images';

export enum Routes {
  MAIN = '/refuse/',
  QUIZ = '/refuse/quiz',
  OFFERS = '/refuse/offers',
  SIGN_IN = '/refuse/sign-in',
  ACTIVATE_ME = '/refuse/activate-me/*',
  SUBSCRIPTION = '/refuse/subscription',
  ADVANTAGES = '/refuse/advantages',
  PRIVACY = '/refuse/privacy',
  TERMS = '/refuse/terms',
  COOKIE_POLICY = '/refuse/cookie-policy',
  DOWNLOAD_APP = '/refuse/congrats',
  UNSUBSCRIBE = '/refuse/unsubscription',
}

function App() {
  const { paymentConfig } = usePaymentConfig();

  useImagePreloader(imagePaths);

  const publicKey = String(paymentConfig?.recurly?.pk || process.env.REACT_APP_RECURLY_KEY);

  return (
    <RecurlyProvider key={publicKey} publicKey={publicKey}>
      <Elements>
        <BrowserRouter>
          <QuizProvider>
            <PaymentProvider>
              <Router />
            </PaymentProvider>
          </QuizProvider>
        </BrowserRouter>
      </Elements>
    </RecurlyProvider>
  );
}

export default App;
