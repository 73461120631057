import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import PaymentConfigProvider from '@providers/PaymentConfigProvider';
import UserProvider from './providers/UserProvider';
import { wmq } from '@services/magnus';

wmq.init();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <UserProvider>
    <PaymentConfigProvider>
      <App />
    </PaymentConfigProvider>
  </UserProvider>
);
