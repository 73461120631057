import { addDays, addMonths, addYears, format } from 'date-fns';

export const endTrial = (trialUnit: string, trialLength: number) => {
  if (trialUnit.includes('d')) {
    const dateEnd = addDays(new Date(), trialLength);

    return format(dateEnd, 'MMMM d, yyyy');
  }

  if (trialUnit.includes('m')) {
    const dateEnd = addMonths(new Date(), trialLength);

    return format(dateEnd, 'MMMM d, yyyy');
  }

  if (trialUnit.includes('y')) {
    const dateEnd = addYears(new Date(), trialLength);

    return format(dateEnd, 'MMMM d, yyyy');
  }
};

export const getParamByKey = (key: string) =>
  new URLSearchParams(document.location.search).get(key);

export function filterFalsyValues(object: Record<string, any>) {
  try {
    const keys = Object.keys(object);
    keys.forEach((key) => {
      if (object[key] == null || object[key] === '') {
        delete object[key];
      }
    });
    return object;
  } catch (e) {
    console.error('[removeFalsyValuesFromObject error]: ', e);
  }
  return object;
}

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
