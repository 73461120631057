import { Box, Typography } from '@mui/material';

function OffersHeader({ steps, stepNumber }: { steps: Array<{ name: string }>; stepNumber: number }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '500px',
          width: '100%',
        }}
      >
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} margin={'17px 0 15px'} padding={'0 20px'}>
          <img width={'108px'} src={'/refuse/svg/refuse_logo.svg'} alt="logo"></img>
        </Box>

        <Box
          sx={{
            padding: '0 20px 10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {steps.map((step, index, arr) => (
            <>
              <Box
                key={step.name}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '5px',
                  background: index <= stepNumber ? '#fff' : undefined,
                  border: index < stepNumber ? undefined : '1px solid rgba(2, 2, 2, 0.10)',
                  borderRadius: '8px',
                  padding: '8px 0px 5px',
                }}
              >
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    background: 'rgba(2, 2, 2, 0.10)',
                    fontFamily: 'Avenir600',
                    fontSize: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {index === stepNumber && (
                    <img width={24} src={'/refuse/svg/sign_in_step_icon.svg'} alt="checked"></img>
                  )}
                  {index < stepNumber && (
                    <img width={24} src={'/refuse/svg/checked_icon_green.svg'} alt="checked"></img>
                  )}
                </Box>
                <Typography
                  sx={{
                    fontFamily: 'Avenir500',
                    fontSize: '10px',
                    color: index <= stepNumber ? '#020202' : 'rgba(2, 2, 2, 0.50)',
                    userSelect: 'none',
                    lineHeight: '12px',
                    textAlign: 'center',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {step.name}
                </Typography>
              </Box>
              {index !== arr.length - 1 && (
                <Box
                  key={step.name + 'divider'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      height: '1px',
                      background: 'rgba(2, 2, 2, 0.10)',
                      width: '10px',
                    }}
                  ></Box>
                </Box>
              )}
            </>
          ))}
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: '460px',
            margin: 'auto',
            height: '1px',
            background: `rgba(2, 2, 2, 0.10)`,
          }}
        ></Box>
      </Box>
    </Box>
  );
}

export default OffersHeader;
